const state = {
  schedules: {
    delivery: process.env.VUE_APP_SCHEDULE_DELIVERY,
    deliveryAndDevolution: process.env.VUE_APP_SCHEDULE_DELIVERY_DEVOLUTION,
    devolution: process.env.VUE_APP_SCHEDULE_DEVOLUTION,
    inspection: process.env.VUE_APP_SCHEDULE_INSPECTION,
    rescheduling: process.env.VUE_APP_SCHEDULE_RESCHEDULING,
    schedule: process.env.VUE_APP_SCHEDULE_SCHEDULE
  }
};

const getters = {
  schedules: state => state.schedules
};


export {
  state,
  getters
}
