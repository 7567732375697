import Vue from 'vue';

const state = {
  is_load_fleet_tickets: true,
  contracts: {
    items: []
  },
  vehicleMonitored: null
}

const getters = {}

const mutations = {
  setLoad(state, obj){
    state.is_load_fleet_tickets = obj;
  },
  setContracts(state, obj) {
    state.contracts = obj;
  },
  setVehicleMonitored(state, obj) {
    state.vehicleMonitored = obj;
  },
  clearVehicleMonitored(state) {
    state.vehicleMonitored = null;
  }
}

const actions = {
  getContracts({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('setLoad', true);
      commit('setContracts', { items: [] });
      Vue.prototype.$fleetProvider.get(
        `/fleet-tickets/tickets/provider/contracts?page=${filters.page_number || 1}&limit=${filters.total | 20}&placa=${filters.placa}&status=${filters.status}`)
        .then((res) => {
          commit('setLoad', false);
          commit('setContracts', res.data || { items: [] });
          resolve(res.data || { items: [] });
        })
        .catch((error) => {
          commit('setLoad', false);
          reject(error);
        })
    })
  },
  vehicleIsMonitored ({ commit, state }, licensePlate) {
    if (state.vehicleMonitored?.licensePlate === licensePlate) return state.vehicleMonitored;
    commit('setLoad', true);
    return new Promise((resolve, reject) => {
      Vue.prototype.$fleetProvider.get(`/fleet-tickets/tickets/provider/vehicle-is-monitored/${licensePlate}`)
      .then((res) => {
          commit('setVehicleMonitored', res.data);
          resolve(res.data)
        })
        .catch((error) => {
          commit('clearVehicleMonitored');
          return reject(error)
        })
        .finally(() => commit('setLoad', false))
    })
  }
}

export { state, getters, mutations, actions }
