import AuthFilter from './auth';
import NProgress from 'nprogress/nprogress';
import routes from './routes';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'page',
});

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'open active',
  async scrollBehavior(to, from, savedPosition) {
    if (document.readyState !== 'complete') {
      await new Promise(resolve => {
        const callback = () => {
          window.requestAnimationFrame(resolve);
          window.removeEventListener('load', callback);
        };
        window.addEventListener('load', callback);
      });
    }

    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return new Promise(resolve => {
      setTimeout(() => resolve({ y: 0 }), 200);
    });
  },
});

// Apply auth filter
router.beforeEach(AuthFilter);

router.afterEach((to) => {

  // Track user page navigation on segment
  Vue.nextTick().then(() => {
    // Track Segment Analytics
    window.analytics.page(to.name, {
      query: to.query,
      params: to.params,
    });
    // Track NewRelic browser
    window.newrelic.interaction().save();
  });

  // Force progressbar get done
  setTimeout(() => NProgress.done(), 6000);
});

// Start progress bar
router.beforeResolve((to, from, next) => {
  if (from.name) NProgress.start();
  next();
});

export default router;
