import { statusItems } from '@entities/corrective-status'
import { formItems } from '@entities/corrective-form'

export const state = {
  currentId: '',
  corrective: {},
  modalState: '',
  statusState: {},
  statusStateSelected: '',
  formState: {}
};

export const getters = {
  g_currentId: ({ currentId }) => currentId,
  g_corrective: ({ corrective }) => corrective,
  g_modalState: ({ modalState }) => modalState,
  g_statusState: ({ statusState }) => statusState,
  g_statusStateSelected: ({ statusStateSelected }) => statusStateSelected,
  g_formState: ({ formState }) => formState
};

export const mutations = {
  m_currentId(state, currentId) {
    state.currentId = currentId
  },
  m_corrective(state, corrective) {
    state.corrective = corrective;
  },
  m_modalState(state, modalState) {
    state.modalState = modalState
  },
  m_statusState(state, statusState) {
    state.statusState = statusState;
  },
  m_statusStateSelected(state, statusStateSelected) {
    state.statusStateSelected = statusStateSelected;
  },
  m_formState(state, formState) {
    state.formState = formState;
  }
};

export const actions = {
  a_currentId({commit}, currentId) {
    commit('m_currentId', currentId);
  },
  a_corrective({commit}, corrective) {
    commit('m_corrective', corrective);
  },
  a_formState({commit}, formState) {
    commit('m_formState', formState);
  },
  a_modalState({commit}, modalState) {
    commit('m_modalState', modalState);
    commit('m_formState', formItems.INITIAL);
    switch(modalState) {
      case 'INITIAL':
        commit('m_statusState', statusItems.INITIAL);
        break;
      case 'READY_FOR_PICKUP':
        commit('m_statusState', statusItems.READY_FOR_PICKUP);
        break;
      case 'SCHEDULED':
        commit('m_statusState', statusItems.SCHEDULED);
        break;
      case 'CANCELED':
        commit('m_statusState', statusItems.CANCELED);
        break;
      case 'PENDING_BUDGET':
        commit('m_statusState', statusItems.PENDING_BUDGET);
        break;
      case 'IN_REPAIR':
        commit('m_statusState', statusItems.IN_REPAIR);
        break;
      case 'DONE':
        commit('m_statusState', statusItems.DONE);
        break;
      default:
        commit('m_statusState', statusItems.INITIAL);
    }
  },
  a_statusState({commit}, statusState) {
    commit('m_statusStateSelected', statusState);
    switch(statusState) {
      case 'INITIAL':
        commit('m_formState', formItems.INITIAL);
        break;
      case 'READY_FOR_PICKUP':
        commit('m_formState', formItems.READY_FOR_PICKUP);
        break;
      case 'SCHEDULED':
        commit('m_formState', formItems.SCHEDULED);
        break;
      case 'CANCELED':
        commit('m_formState', formItems.CANCELED);
        break;
      case 'PENDING_BUDGET':
        if(state.modalState === 'INITIAL') {
          commit('m_formState', formItems.CREATE);
        } else {
          commit('m_formState', formItems.PENDING_BUDGET);
        }
        break;
      case 'IN_REPAIR':
        commit('m_formState', formItems.IN_REPAIR);
        break;
      case 'DONE':
        commit('m_formState', formItems.DONE);
        break;
      default:
        commit('m_formState', formItems.INITIAL);
    }
  }
};
