import apolloClient from '@utils/graphql';
import FLEET_HEADER from '@graphql/car/queries/header.gql';
import NProgress from 'nprogress/nprogress';
import { enumFleetFlags, enumOBDProvider } from '@utils/car';
import { getByLicensePlate as getByLicensePlateService } from '@graphql/car/services';
import axios from "axios";

const state = {
  header: {
    id: '',
    vin: '',
    license_plate: 'Carregando...',
    brand: {
      id: '',
      name: '',
    },
    model: {
      id: '',
      name: '',
    },
    fuel: {
      id: '',
      name: '',
    },
    model_year: 0,
    assemble_year: 0,
    total_fines: 0,
    status: '',
    status_operation: '',
  },
  flags: [],
  lastId: null
};

const getters = {
  header: state => state.header,
  getFleetTagsName: state => state.flags.map((flag) => (
    enumOBDProvider.concat(enumFleetFlags).find((el) => el.value === flag.name).text
  )),
  getFleetTagsObj: state => state.flags.map((flag) => ({
    ...flag,
    name: enumOBDProvider.concat(enumFleetFlags).find((el) => el.value === flag.name).text,
  }))
};

const mutations = {
  setHeader(state, header) {
    state.header = header;
  },
  setStatusOp(state, headerStatusOp) {
    state.header.status_operation = headerStatusOp;
  },
  setFlags(state, flags) {
    state.flags = flags;
  },
  clearFlags(state) {
    state.flags = []
  },
  setLastId(state, id) {
    state.lastId = id;
  }
};

const actions = {
  clearHeader({ commit }) {
    commit('setHeader', {
      id: '',
      vin: '',
      license_plate: 'Carregando...',
      brand: {
        id: '',
        name: '',
      },
      model: {
        id: '',
        name: '',
      },
      fuel: {
        id: '',
        name: '',
      },
      model_year: 0,
      assemble_year: 0,
      total_fines: 0,
      status: '',
      status_operation: '',
    });
  },
  async header({ commit }, { id }) {
    NProgress.inc();
    const result = await apolloClient.query({
      query: FLEET_HEADER,
      variables: {
        id,
      },
    });
    if (result) {
      result.data?.car?.status_operation === 'LOCKED' ? 'Desbloquear' : 'Bloquear';
      NProgress.done();
      commit('setHeader', result.data.car);
    }
  },
  getByLicensePlate(_, { license_plate } = {}) {
    return getByLicensePlateService({ license_plate });
  },
  setStatusOperation({ commit }, headerStatusOp) {
    commit('setStatusOp', headerStatusOp);
  },
  getFlags({commit, state}, obj) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (state.lastId === obj.id) return resolve(state.flags);
      commit('clearFlags');
      commit('setLastId', obj.id);
      await axios.get(`${process.env.KOVI_FLEET_API_GATEWAY}/fleet-tags/fleet-tags/list-tags/${obj.id}`, {headers: obj.headers})
        .then((res) => {
          commit('setFlags', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
};

export { state, getters, actions, mutations };
