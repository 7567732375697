import gql from 'graphql-tag';
import apolloClient from '@utils/graphql';
import NProgress from 'nprogress/nprogress';
import ImageConversion from 'image-conversion';
import {DriverDocumentEntity} from '@entities/driver-document';

// const appsType = ['APP_UBER', 'APP_99', 'APP_CABIFY', 'APP_LADYDRIVER', 'APP_OUTROS'];

export const state = {
  items: [],
  cnh: [],
  address: [],
  garage: [],
  apps: [],
};

export const getters = {
  items: state => state.items,
  cnh: state => state.cnh,
  address: state => state.address,
  garage: state => state.garage,
  apps: state => state.apps,
};

export const mutations = {
  setItems(state, items) {
    if (!items || !items.length) {
      state.items = [];
      return;
    }
    state.items = items;
  },

  setCnh(state, items) {
    if (!items || !items.length) {
      state.cnh = [];
      return;
    }

    state.cnh = items.filter(item => item.isTypeCnh());
  },

  setAddress(state, items) {
    if (!items || !items.length) {
      state.address = [];
      return;
    }

    state.address = items.filter(item => item.isTypeAddress());
  },

  setGarage(state, items) {
    if (!items || !items.length) {
      state.garage = [];
      return;
    }

    state.garage = items.filter(item => item.isTypeGarage());
  },

  setApps(state, items) {
    if (!items || !items.length) {
      state.apps = [];
      return;
    }

    let stored = {
      uber: false,
      cabify: false,
      ladydriver: false,
      others: false,
    };

    state.apps = items.filter(item => {
      if (item.isTypeAppUber() && !stored.uber) {
        return stored.uber = true;
      }

      if (item.isTypeAppCabify() && !stored.cabify) {
        return stored.cabify = true;
      }

      if (item.isTypeAppLadyDriver() && !stored.ladydriver) {
        return stored.ladydriver = true;
      }

      if (item.isTypeAppOthers() && !stored.others) {
        return stored.others = true;
      }

      return false;
    });
  },
};

export const actions = {
  reset({commit}) {
    commit('setCnh', []);
    commit('setAddress', []);
    commit('setGarage', []);
    commit('setApps', []);
    commit('setItems', []);
  },

  getByDriverId({commit, dispatch}, {driverId, conditionals}) {
    apolloClient.cache.reset();
    apolloClient.query({
      query: gql`
          query DriversDocumentByDriverIdGET(
            $driver_id: ID!
            $conditionals: DriverDocumentConditionalsInput
          ) {
            driversDocumentByDriverId(driver_id: $driver_id, conditionals: $conditionals) {
              items {
                id
                driver_id
                status
                type
                url
                reject_reasons
              }
            }
          }
        `,
      variables: {
        driver_id: driverId,
        conditionals,
      },
    }).then(({data}) => {
      let documentsPayloadReceived = data.driversDocumentByDriverId.items;
      const driverDocuments = documentsPayloadReceived.map(item =>
        DriverDocumentEntity.of(item));

      commit('setCnh', driverDocuments);
      commit('setAddress', driverDocuments);
      commit('setGarage', driverDocuments);
      commit('setApps', driverDocuments);
      commit('setItems', driverDocuments);
    }).catch(() => {
      dispatch('reset');
    });
  },

  uploadCnh({dispatch}, {driverId, file}) {
    return dispatch('upload', {
      driverId,
      documentType: 'cnh_photo',
      file,
    });
  },

  uploadAddress({dispatch}, {driverId, file}) {
    return dispatch('upload', {
      driverId,
      documentType: 'address_photo',
      file,
    });
  },

  uploadGarage({dispatch}, {driverId, file}) {
    return dispatch('upload', {
      driverId,
      documentType: 'garage_photo',
      file,
    });
  },

  uploadProofOfPayment({dispatch}, {driverId, file}) {
    return dispatch('upload', {
      driverId,
      documentType: 'proof_of_payment',
      file,
    });
  },

  updateApps({dispatch}, apps) {
    return dispatch('setApps', apps);
  },

  async upload({dispatch}, {driverId, documentType, file}) {
    if (!documentType || !file) return;

    const loadImage = async file => {
      return new Promise((resolve, reject) => {
        NProgress.inc();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
          NProgress.done();
        };
        reader.onerror = () => {
          reject(null);
          NProgress.done();
        };
      });
    };
    const compress = async image => {
      const imageType = image.split(';')[0].replace('data:', '');
      const imageLoaded = await ImageConversion.dataURLtoFile(image);
      const compressedImage = await ImageConversion.compress(imageLoaded, {
        quality: 0.8,
        type: imageType,
        width: 1000,
      });
      const finalImage = await ImageConversion.filetoDataURL(compressedImage);
      return finalImage;
    };
    const image = typeof file !== typeof '' ? await loadImage(file) : file;
    const compressedImage = await compress(image);

    return new Promise((resolve, reject) => {
      NProgress.inc();
      let formData = new FormData();
      formData.append('data', compressedImage);
      formData.append('documentType', documentType);
      formData.append('driverId', driverId);
      fetch(`${ process.env.KOVI_API_URL }/docs`, {
        method: 'POST',
        body: formData,
      }).then(result => {
        NProgress.inc();
        return result.json();
      }).then(result => {
        NProgress.inc();
        dispatch('getByDriverId', {
          driverId,
          conditionals: {
            status: ['SENT', 'APPROVED'],
          },
        });
        return result;
      }).then(res => {
        NProgress.done();
        return resolve(res);
      }).catch(() => {
        NProgress.done();
        return reject('Erro ao enviar arquivo')
      });
    });
  },
  // eslint-disable-next-line
  approve({commit}, documentId) {
    return apolloClient.mutate({
      mutation: gql`
        mutation DocumentApproved($id: ID!) {
          approve(id: $id)
        }
      `,
      variables: {
        id: documentId,
      },
    });
  },
  // eslint-disable-next-line
  disapprove({commit}, documentId) {
    return apolloClient.mutate({
      mutation: gql`
        mutation DocumentReject($id: ID!) {
          reject(id: $id)
        }
      `,
      variables: {
        id: documentId,
      },
    });
  },
};
