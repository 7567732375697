const state = {
  modalData: {},
}

const getters = {
  modalData: currentState => currentState.modalData,
}

const mutations = {
  setModalData(state, value) {
    state.modalData = value;
  },

  resetModalData(state) {
    state.modalData = {};
  }
}

export {
  state,
  getters,
  mutations,
}
