const endpoint = process.env.WEBSOCKET_URL || 'wss://wa-api.kovi.us/pubsub';

const WS_READYSTATE = {
  //	Socket has been created. The connection is not yet open.
  CONNECTING: 0,
  //	The connection is open and ready to communicate.
  OPEN: 1,
  //	The connection is in the process of closing.
  CLOSING: 2,
  CLOSED: 3
}
const sockets = {};
export default {
  install: function (Vue) {
    Vue.prototype.$pubsub = (topic) => {
      let _callback;
      let ws = sockets[topic];
      return {
        subscribe: async (callback) => {
          _callback = callback;
          return await subscribe(topic, callback);
        },
        publish: async (data) => {
          if (!ws || ws.readyState !== WS_READYSTATE.OPEN) {
            await subscribe(topic, _callback);
          }
          data.action  = data.action || 'publish'
          data.topic = topic;
          ws.send(JSON.stringify(data))
        }
      }
    }
  }
}

const subscribe = async (topic, callback) => {

  let ws = sockets[topic];
  if (ws && ws.readyState === WS_READYSTATE.OPEN) {
    setSubcriber(ws, topic, callback);
    return ws;
  }

  return await new Promise((ok, nok) => {
    ws = sockets[topic] = new WebSocket(endpoint);
    ws.onopen = () => {
      setSubcriber(ws, topic, callback);
      ok(ws);
    };
    ws.onerror = (err) => nok(err)
  });

}

const setSubcriber = (ws, topic, callback) => {
  if (!callback) return;
  ws.onmessage = (event) => {
    const obj = JSON.parse(event.data);
    callback(obj);
  }
  ws.send(JSON.stringify({ action: 'subscribe', topic }));
}