import SentryManager from '@src/lib/sentry';
import { fetchEntityRegionService } from '@graphql/user/services';

export const state = {
  id: null,
  authToken: null,
  groups: [],
  attributes: [],
  payload: null,
  verification: null,
  region: null,
  schedulePlace: {
    delivery: {
      placeId: null,
      datetime: null
    },
    devolution: {
      placeId: null,
      datetime: null
    }
  },
  entityRegion: {
    region: {
      city: '',
      group: '',
      state: '',
      country: ''
    }
  }
};

export const mutations = {
  setEntityRegion(state, data) {
    state.entityRegion = data;
  },
  setId(state, id) {
    state.id = id;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setAuthToken(state, token) {
    state.authToken = token;
  },
  setRegion(state, region) {
    state.region = region;
    SentryManager.setTag('region', region);
  },
  setAttributes(state, attributes) {
    state.attributes = attributes;

    SentryManager.setUser({
      id: attributes.id,
      name: attributes.name,
      email: attributes.email,
    });
  },
  setUserVerification(state, verification) {
    state.verification = !!verification;
  },
  logout(state) {
    state.id = null;
    state.authToken = null;
    state.groups = [];
    state.attributes = [];
    state.verification = null;
    state.region = null;
  },
  setDeliverySchedulePlace(state, values) {
    state.schedulePlace.delivery = {
      placeId: values.placeId,
      datetime: values.datetime
    }
  },
  setDevolutionSchedulePlace(state, values) {
    state.schedulePlace.devolution = {
      placeId: values.placeId,
      datetime: values.datetime
    }
  },
};

export const getters = {
  // Whether the user is currently logged in.
  id: ({ id }) => id,
  authToken: ({ authToken }) => authToken,
  groups: ({ groups }) => groups,
  attributes: ({ attributes }) => attributes,
  region: ({ region }) => region,
  country: ({ region }) => region.substr(0,2),
  findGroups: (state) => (group) => {
    return state.groups.includes(group)
  },
  deliverySchedulePlace: ({ schedulePlace }) => schedulePlace.delivery,
  devolutionSchedulePlace: ({ schedulePlace }) => schedulePlace.devolution,
  entityRegion: ({ entityRegion }) => entityRegion,
};

export const actions = {
  changeRegion({ commit }, region) {
    commit('setRegion', region);
  },
  changeDeliverySchedulePlace({ commit }, delivery) {
    commit('setDeliverySchedulePlace', delivery);
  },
  changeDevolutionSchedulePlace({ commit }, devolution) {
    commit('setDevolutionSchedulePlace', devolution);
  },
  async fetchEntityRegion({ commit }, {
    entity,
    id
  }) {
    try {
      const { data: { entityRegion: { region } } } = await fetchEntityRegionService({
        entity,
        id,
      });

      commit('setEntityRegion', region);
      return region;
    } catch (e) {
      console.error(e);
    }
  }
};
