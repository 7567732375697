import Vue from 'vue'
import { isCNH } from 'validation-br';
import { localize, ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { isBrazil, isMexico } from '@utils/helper-region'
import i18next from 'i18next';
import { checkCurp } from 'utils-validator-kovi'

extend('required', required)

extend('cpfValid', {
  validate: function (value) {
    let cpf
    cpf = value.replace(/[^\d]+/g, '');

    let numeros,
        digitos,
        soma,
        i,
        resultado,
        digitosIguais;
    digitosIguais = 1;

    if (cpf.length < 11) return false;

    for (i = 0; i < cpf.length - 1; i++) {
      if (Number(cpf.charAt(i)) !== Number(cpf.charAt(i + 1))) {
        digitosIguais = 0;
        break;
      }
    }

    if (!digitosIguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;

      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado !== Number(digitos.charAt(0))) return false;

      numeros = cpf.substring(0, 10)
      soma = 0

      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== Number(digitos.charAt(1))) return false;

      return true;
    } else {
      return false;
    }
  },
  message: 'Cpf Inválido'
})

extend('validateCurp', {
  validate: function (curp) {
    const result = checkCurp(curp)
    return result
  },
  message: 'Curp Inválido'
})

extend('validatePhone', {
  validate: function (value) {
    let valid = true
    if (isBrazil() && value.length < 12) valid = false
    if (isMexico() && value.length < 12) valid = false
    return valid
  },
  message: i18next.t('drivers.texts.phoneInvalid')
})

extend('validateRfc', {
  validate: function (value) {
    let valid = true
    if (isMexico() && value.length < 5) valid = false
    return valid
  },
  message: i18next.t('drivers.texts.rfcInvalid')
})

extend('cnhValid', {
  validate: function (value) {
    let valid = true
    if (isBrazil() && !isCNH(value)) valid = false
    if (isMexico() && value.length < 3) valid = false
    return valid
  },
  message: i18next.t('drivers.texts.licenceNumberInvalid')
})

extend('emailValid', {
  validate: function (value) {
    let valid = true
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    valid = regex.test(value)
    return valid
  },
  message: i18next.t('drivers.texts.emailInvalid')
})

extend('signupNameValid', {
  validate: function (value) {
    let name = value.split(' ')

    if (name.length < 2) return false;

    return true;
  },
  message: 'Digite o nome e sobrenome.'
})

extend('signupPhoneValid', {
  validate: function (value) {
    let valid = true
    if (isBrazil() && value.length < 11) valid = false
    if (isMexico() && value.length < 12) valid = false
    return valid
  },
  message: i18next.t('drivers.texts.phoneInvalid')
})

extend('signupLicenseStateValid', {
  validate: function (value) {
    if (value === 'Escolha uma UF') return false
    return true
  },
  message: 'O campo Estado emissor da CNH é obrigatório'
})

localize({
  en: {
    messages: {
      required: i18next.t('drivers.texts.requiredInput')
    }
  }
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
