import { i18next } from '@translate/i18n';

const router = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      auth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@views/home'),
    meta: {
      auth: true,
    },
  },
  {
    name: 'DriverPrintNegotiation',
    path: '/negotiations/print',
    component: () => import('@views/drivers/negotiations-print'),
  },
  {
    path: '/drivers',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: 'cnh-checker/:id?',
        name: 'CNHChecker',
        component: () => import('@views/drivers/cnh-checker'),
        meta: {
          auth: false,
          title: i18next.t('drivers.titles.driverRegister'),
        },
      },
      {
        path: '',
        component: () => import('@views/drivers'),
        meta: {
          auth: true,
        },
        children: [
          {
            path: '',
            redirect: '/drivers/active',
          },
          {
            path: 'active',
            name: 'DriversActive',
            component: () => import('@views/drivers/list-by-status'),
            meta: {
              auth: true,
              status: ['ACTIVE'],
            },
          },
          {
            path: 'banned',
            name: 'DriversBanned',
            component: () => import('@views/drivers/list-by-status'),
            meta: {
              auth: true,
              status: ['BANNED'],
              title: i18next.t('drivers.titles.driverBaned'),
            },
          },
          {
            path: 'overdue',
            name: 'DriversOverdue',
            component: () => import('@views/drivers/overdue'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'schedules',
            name: 'DriversSignupDeliveryScheduled',
            component: () => import('@views/drivers/signup'),
            meta: {
              auth: true,
              step: ['DELIVERY_SCHEDULED', 'DELIVERY_NOSHOW'],
              fields: [
                {
                  name: 'name',
                  title: i18next.t('drivers.labels.name'),
                  sortField: 'name',
                },
                // {
                //   name: 'fiscal_number',
                //   title: i18next.t('drivers.labels.rfc'),
                //   sortField: 'cpf'
                // },
                {
                  name: 'cpf',
                  title: i18next.t('drivers.labels.document'),
                  sortField: 'cpf',
                },
                {
                  name: 'step_updated_at',
                  title: i18next.t('drivers.labels.updateStepDate'),
                  sortField: 'step_updated_at',
                },
                {
                  name: 'booking.car_scheduled_at',
                  title: i18next.t('drivers.labels.deliveryDate'),
                },
                {
                  name: 'step',
                  title: i18next.t('drivers.labels.stepRegister'),
                  sortField: 'step',
                },
              ],
              title: i18next.t('drivers.titles.driverScheduled'),
            },
          },
          {
            path: 'scheduled-devolution',
            name: 'ScheduledDevolution',
            component: () => import('@views/drivers/schedules/ScheduledDevolution'),
            meta: {
              auth: true,
              kind: 'DEVOLUTION',
              filters: {
                status: 'SCHEDULED',
              },
            },
          },
          {
            path: 'scheduled-delivery',
            name: 'ScheduledDelivery',
            component: () => import('@views/drivers/schedules/ScheduledDelivery'),
            meta: {
              auth: true,
              kind: 'DELIVERY',
              filters: {
                status: 'SCHEDULED',
              },
            },
          },
          {
            path: 'scheduled-inspection',
            name: 'ScheduledInspection',
            component: () => import('@views/drivers/schedules/ScheduledInspection'),
            meta: {
              auth: true,
              kind: 'INSPECTION',
              filters: {
                status: 'SCHEDULED',
              },
            },
          },
          {
            path: 'signup/docs/detail/:id',
            name: 'DriversSignupProfile',
            component: () => import('@views/drivers/signup/detail'),
            meta: {
              auth: true,
              title: i18next.t('drivers.titles.driverRegister'),
            },
          },
          {
            path: 'signup/overview',
            name: 'DriversSignupOverview',
            component: () => import('@views/drivers/signup/overview'),
            meta: {
              auth: true,
              title: i18next.t('driversSignup.titles.overview'),
            },
          },
          {
            path: 'signup',
            name: 'DriversSignup',
            redirect: '/drivers/signup/overview',
            component: () => import('@views/drivers/signup'),
            children: [
              {
                path: 'waiting-list',
                name: 'DriversSignupWaitingList',
                meta: {
                  auth: true,
                  step: ['BGC_REJECTED'],
                  sort: [['step_updated_at', 'ASC']],
                  fields: [
                    {
                      name: 'name',
                      title: i18next.t('drivers.labels.name'),
                      sortField: 'name',
                    },
                    // {
                    //   name: 'fiscal_number',
                    //   title: i18next.t('drivers.labels.rfc'),
                    //   sortField: 'cpf'
                    // },
                    {
                      name: 'cpf',
                      title: i18next.t('drivers.labels.document'),
                      sortField: 'cpf',
                    },
                    {
                      name: 'license_points',
                      title: i18next.t('drivers.labels.licencePoints'),
                      sortField: 'license_points',
                    },
                    {
                      name: 'step_updated_at',
                      title: i18next.t('drivers.labels.updateStepDate'),
                      sortField: 'step_updated_at',
                    },
                    {
                      name: 'created_at',
                      title: i18next.t('drivers.labels.registerDate'),
                      sortField: 'created_at',
                    },
                  ],
                  title: i18next.t('drivers.titles.driversReject'),
                },
              },
              {
                path: 'lead',
                name: 'DriversSignupLeads',
                meta: {
                  auth: true,
                  step: ['LEAD'],
                  fields: ['name', 'phone_number', 'step_updated_at', 'step', 'edit'],
                  title: i18next.t('drivers.titles.basicInformation'),
                },
              },
              {
                path: 'docs',
                name: 'DriversSignupDocs',
                children: [
                  {
                    path: 'pending',
                    name: 'DriversSignupDocsPending',
                    meta: {
                      auth: true,
                      step: ['APPS_PENDING', 'DOCS_PENDING', 'DOCS_REJECTED'],
                      fields: [
                        {
                          name: 'name',
                          title: i18next.t('drivers.labels.name'),
                          sortField: 'name',
                        },
                        // {
                        //   name: 'fiscal_number',
                        //   title: i18next.t('drivers.labels.rfc'),
                        //   sortField: 'cpf'
                        // },
                        {
                          name: 'cpf',
                          title: i18next.t('drivers.labels.document'),
                          sortField: 'cpf',
                        },
                        {
                          name: 'step_updated_at',
                          title: i18next.t('drivers.labels.updateStepDate'),
                          sortField: 'step_updated_at',
                        },
                        {
                          name: 'created_at',
                          title: i18next.t('drivers.labels.registerDate'),
                          sortField: 'created_at',
                        },
                        {
                          name: 'step',
                          title: i18next.t('drivers.labels.stepRegister'),
                          sortField: 'step',
                        },
                      ],
                      title: i18next.t('drivers.titles.waitingForDocuments'),
                    },
                  },
                  {
                    path: 'checking',
                    name: 'DriversSignupDocsChecking',
                    meta: {
                      auth: true,
                      step: ['DOCS_CHECKING', 'BGC_REJECTED'],
                      filters: {
                        bgc_status: ['APPROVED', 'APPROVED_MANUALLY', 'MISSING_FINANCE'],
                      },
                      sort: [['step_updated_at', 'ASC']],
                      fields: [
                        {
                          name: 'name',
                          title: i18next.t('drivers.labels.name'),
                          sortField: 'name',
                        },
                        // {
                        //   name: 'fiscal_number',
                        //   title: i18next.t('drivers.labels.rfc'),
                        //   sortField: 'cpf'
                        // },
                        {
                          name: 'cpf',
                          title: i18next.t('drivers.labels.document'),
                          sortField: 'cpf',
                        },
                        {
                          name: 'license_points',
                          title: i18next.t('drivers.labels.licencePoints'),
                          sortField: 'license_points',
                        },
                        {
                          name: 'bgc_status',
                          title: 'Status do BGC',
                        },
                        {
                          name: 'step_updated_at',
                          title: i18next.t('drivers.labels.updateStepDate'),
                          sortField: 'step_updated_at',
                        },
                        {
                          name: 'created_at',
                          title: i18next.t('drivers.labels.registerDate'),
                          sortField: 'created_at',
                        },
                      ],
                      title: i18next.t('drivers.titles.docsChecking'),
                    },
                  },
                ],
              },
              {
                path: 'invite',
                name: 'DriversSignupInvite',
                meta: {
                  auth: true,
                  step: ['DOCS_APPROVED', 'INVITE_PENDING'],
                  sort: [['kovi_signup_score', 'DESC']],
                  fields: [
                    {
                      name: 'name',
                      title: i18next.t('drivers.labels.name'),
                      sortField: 'name',
                    },
                    {
                      name: 'cpf',
                      title: i18next.t('drivers.labels.document'),
                      sortField: 'cpf',
                    },
                    {
                      name: 'step_updated_at',
                      title: i18next.t('drivers.labels.updateStepDate'),
                      sortField: 'step_updated_at',
                    },
                    {
                      name: 'created_at',
                      title: i18next.t('drivers.labels.registerDate'),
                      sortField: 'created_at',
                    },
                    {
                      name: 'step',
                      title: i18next.t('drivers.labels.stepRegister'),
                      sortField: 'step',
                    },
                    {
                      name: 'send_invite',
                      title: i18next.t('drivers.buttons.inviteSend'),
                    },
                    {
                      name: 'kovi_signup_score',
                      title: i18next.t('drivers.labels.koviSignupScore'),
                      sortField: 'kovi_signup_score',
                    },
                    {
                      name: 'utm.utm_plan',
                      title: 'Utm',
                    },
                  ],
                  title: i18next.t('drivers.titles.invite'),
                },
              },
              {
                path: 'payments',
                name: 'DriversSignupPayments',
                redirect: '/drivers/signup/payments/pending',
                children: [
                  {
                    path: 'pending',
                    name: 'DriversSignupPaymentsPending',
                    meta: {
                      auth: true,
                      step: ['PAYMENT_PENDING_PAYMENT', 'PAYMENT_PENDING', 'PLAN_PENDING'],
                      fields: [
                        {
                          name: 'name',
                          title: i18next.t('drivers.labels.name'),
                          sortField: 'name',
                        },
                        // {
                        //   name: 'fiscal_number',
                        //   title: i18next.t('drivers.labels.rfc'),
                        //   sortField: 'cpf'
                        // },
                        {
                          name: 'cpf',
                          title: i18next.t('drivers.labels.document'),
                          sortField: 'cpf',
                        },
                        {
                          name: 'step_updated_at',
                          title: i18next.t('drivers.labels.updateStepDate'),
                          sortField: 'step_updated_at',
                        },
                        {
                          name: 'step',
                          title: i18next.t('drivers.labels.stepRegister'),
                          sortField: 'step',
                        },
                        {
                          name: 'kovi_signup_score',
                          title: i18next.t('drivers.labels.koviSignupScore'),
                          sortField: 'kovi_signup_score',
                        },
                      ],
                      sort: [['kovi_signup_score', 'DESC']],
                      title: i18next.t('drivers.titles.payments'),
                    },
                  },
                ],
              },
              {
                path: 'delivery',
                name: 'DriversSignupDeliverySchedules',
                meta: {
                  auth: true,
                  step: ['DELIVERY_PENDING', 'DELIVERY_NOSHOW', 'DELIVERY_SCHEDULED'],
                  fields: [
                    {
                      name: 'name',
                      title: i18next.t('drivers.labels.name'),
                      sortField: 'name',
                    },
                    // {
                    //   name: 'fiscal_number',
                    //   title: i18next.t('drivers.labels.rfc'),
                    //   sortField: 'cpf'
                    // },
                    {
                      name: 'cpf',
                      title: i18next.t('drivers.labels.document'),
                      sortField: 'cpf',
                    },
                    {
                      name: 'step_updated_at',
                      title: i18next.t('drivers.labels.updateStepDate'),
                      sortField: 'step_updated_at',
                    },
                    {
                      name: 'booking.car_scheduled_at',
                      title: i18next.t('drivers.labels.deliveryDate'),
                      sortField: 'booking.car_scheduled_at',
                    },
                    {
                      name: 'step',
                      title: i18next.t('drivers.labels.stepRegister'),
                      sortField: 'step',
                    },
                    {
                      name: 'kovi_signup_score',
                      title: i18next.t('drivers.labels.koviSignupScore'),
                      sortField: 'kovi_signup_score',
                    },
                  ],
                  sort: [['kovi_signup_score', 'DESC']],
                  title: i18next.t('drivers.titles.waitingDelivery'),
                },
              },
            ],
          },
        ],
      },
      {
        path: 'profile/:id',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            // name: 'DriverProfile',
            component: () => import('@views/drivers/profile-new'),
            meta: {
              auth: true,
            },
            children: [
              {
                path: '',
                name: 'DriverProfileInfo',
                component: () => import('@views/drivers/profile'),
                meta: {
                  auth: true,
                },
              },
              {
                name: 'DriverWallet',
                path: 'wallet',
                component: () => import('@views/drivers/wallet-new'),
              },
              {
                path: 'balance-history',
                name: 'BalanceHistory',
                component: () => import('@views/drivers/balance-history'),
              },
              {
                path: 'payment-methods',
                name: 'DriverWalletPaymentMethods',
                component: () => import('@views/drivers/payment-methods'),
              },
              {
                name: 'DriverNegotiations',
                path: 'negotiations',
                component: () => import('@views/drivers/negotiations'),
              },
              {
                name: 'DriverNewNegotiation',
                path: 'negotiations/new',
                component: () => import('@views/drivers/negotiations-new'),
              },
              {
                name: 'DriverBookings',
                path: 'bookings/:contract?',
                component: () => import('@views/drivers/bookings'),
              },
              {
                name: 'DriverBlockHistory',
                path: 'block-history',
                component: () => import('@views/drivers/block-history'),
              },
              {
                name: 'DriverFines',
                path: 'fines',
                component: () => import('@views/drivers/fines'),
              },
              {
                name: 'DriverRepayment',
                path: 'repayment',
                component: () => import('@views/drivers/repayment'),
              },
              {
                path: 'edit',
                name: 'DriverProfileEdit',
                component: () => import('@views/drivers/profile-edit'),
                meta: {
                  auth: true,
                },
              },
              {
                name: 'DriverChatTickets',
                path: 'chat-tickets',
                component: () => import('@views/drivers/chat-tickets'),
              },
              {
                name: 'DriverChatEvents',
                path: 'chat-events',
                component: () => import('@views/drivers/chat-events'),
              },
              {
                name: 'DriverChecklist',
                path: 'checklist',
                component: () => import('@views/drivers/checklist'),
                meta: {
                  queryType: 'driver',
                },
              },
            ],
          },
          {
            path: 'lead-edit',
            name: 'DriversSignupProfileEdit',
            component: () => import('@views/drivers/lead-complete-register'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'fines',
            name: 'ProfileDriverFines',
            component: () => import('@views/drivers/fines'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'bookings',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'DriversBookings',
                component: () => import('@views/drivers/bookings'),
                meta: {
                  auth: true,
                  status: 'RECENT',
                },
              },
              {
                path: ':booking/print',
                name: 'DriverBookingContract',
                component: () => import('@views/drivers/booking-contract'),
                meta: {
                  page_title: '',
                  auth: true,
                },
              },
            ],
          },
          {
            path: 'history',
            name: 'DriverHistory',
            component: () => import('@views/drivers/history'),
            meta: {
              auth: true,
            },
          },
        ],
      },
      // {
      //   path: 'profile-new/:id',
      //   component: () => import('@views/drivers/profile-new'),
      //   children: [
      //     {
      //       path: '',
      //       name: 'DriverProfileInfo',
      //       component: () => import('@views/drivers/profile'),
      //       meta: {
      //         auth: true,
      //     },
      //     },
      //     {
      //       path: 'wallet',
      //       component: () => import('@views/drivers/wallet-new'),
      //     },
      //     {
      //       path: 'bookings',
      //       component: () => import('@views/drivers/bookings'),
      //     },
      //     {
      //       path: 'transactions',
      //       component: () => import('@views/drivers/wallet'),
      //     },
      //     {
      //       path: 'fines',
      //       component: () => import('@views/drivers/fines'),
      //     },
      //   ]
      // }
    ],
  },
  {
    path: '/credit-requests',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        component: () => import('@views/credit-requests'),
        meta: {
          auth: true,
        },
        children: [
          {
            path: '',
            redirect: '/credit-requests/pending',
          },
          {
            path: 'pending',
            name: 'Pendentes',
            component: () => import('@views/credit-requests/list-pending'),
            meta: {
              auth: true,
              title: 'Solicitações de Crédito Pendentes',
            },
          },
          {
            path: 'approved',
            name: 'Aprovadas',
            component: () => import('@views/credit-requests/list-approved'),
            meta: {
              auth: true,
              title: 'Solicitações de Crédito Aprovadas',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/ted',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'ted',
        component: () => import('@views/ted'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/ted-failed',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'ted-failed',
        component: () => import('@views/ted/Failed'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/safety',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        redirect: '/safety/br/kovi',
      },
      {
        path: ':country/:provider',
        name: 'safety-map',
        component: () => import('@views/safety'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'automatic-lock',
        name: 'automatic-lock',
        component: () => import('@views/safety/payment-locks'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'v2',
        name: 'safety-v2',
        component: () => import('@views/safety/v2'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/insurance',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        redirect: '/insurance/fleet',
      },
      {
        path: 'fleet',
        name: 'insurance-fleet',
        component: () => import('@views/insurance/fleet'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/fleet',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'fleet',
        component: () => import('@views/fleet'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'old',
        name: 'FleetOld',
        component: () => import('@views/fleet/old'),
        meta: {
          auth: true,
        },
      },
      {
        path: ':id',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'FleetProfile',
            component: () => import('@views/fleet/profile'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'edit',
            name: 'FleetProfileEdit',
            component: () => import('@views/fleet/profile-edit'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'fines',
            name: 'FleetFines',
            component: () => import('@views/fleet/fines'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'alerts',
            name: 'FleetAlerts',
            component: () => import('@views/fleet/alerts'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'bookings',
            name: 'FleetBookings',
            component: () => import('@views/fleet/bookings'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'tracker',
            redirect: 'tracker-v2',
          },
          {
            path: 'tracker-v2',
            name: 'FleetTrackerV2',
            component: () => import('@views/fleet/tracker-v2'),
            meta: {
              auth: true,
            },
          },
          {
            path: 'block-history',
            name: 'FleetBlockHistory',
            component: () => import('@views/fleet/block-histories'),
            meta: {
              auth: true,
            },
          },
          {
            name: 'FleetChecklist',
            path: 'checklist',
            component: () => import('@views/fleet/checklist'),
            meta: {
              queryType: 'car',
            },
          },
          {
            name: 'FleetHistory',
            path: 'history',
            component: () => import('@views/fleet/history'),
            meta: {
              queryType: 'car',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/contracts',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'contracts',
        component: () => import('@views/contracts'),
        meta: {
          auth: true,
        },
      }
    ],
  },
  {
    path: '/fines',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'fines',
        component: () => import('@views/fines'),
        meta: {
          auth: true,
          status: 'PENDING',
        },
      },
      {
        path: 'pending',
        name: 'fines-pending',
        component: () => import('@views/fines'),
        meta: {
          auth: true,
          status: 'PENDING_INDICATION',
        },
      },
      {
        path: 'indicated',
        name: 'fines-indicated',
        component: () => import('@views/fines'),
        meta: {
          auth: true,
          status: 'INDICATED',
        },
      },
      {
        path: 'charged',
        name: 'fines-charged',
        component: () => import('@views/fines'),
        meta: {
          auth: true,
          status: 'CHARGED',
        },
      },
      {
        path: 'paid',
        name: 'fines-paid',
        component: () => import('@views/fines'),
        meta: {
          auth: true,
          status: 'PAID',
        },
      },
    ],
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: 'schedule',
        name: 'MaintenanceSchedules',
        component: () => import('@views/maintenance/schedule'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'scheduled',
        name: 'MaintenanceScheduled',
        component: () => import('@views/maintenance/scheduled'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'garages',
        name: 'MaintenanceGarages',
        component: () => import('@views/maintenance/garages'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'corrective',
        name: 'CorrectiveMaintenance',
        component: () => import('@views/maintenance/corrective'),
        meta: {
          auth: true,
        },
        children: [
          {
            path: ':carStatus',
            component: () => import('@views/maintenance/corrective'),
            meta: {
              auth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/cx',
    name: 'cx',
    component: () => import('@views/cx'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        redirect: '/admin/contracts',
        name: 'Admin',
        meta: {
          auth: true,
        },
      },
      {
        path: 'plans',
        name: 'AdminPlans',
        component: () => import('@views/admin/plans'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'places',
        name: 'AdminPlaces',
        component: () => import('@views/admin/places'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'staff',
        name: 'AdminStaff',
        component: () => import('@views/admin/staff'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'config',
        name: 'AdminConfig',
        component: () => import('@views/admin/config'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/providers',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'providers-table',
        component: () => import('@views/providers'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'contract/:contract_id',
        name: 'ProviderContract',
        component: () => import('@views/providers/contract'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'contract/:contract_id/order/:order_id',
        name: 'ProviderOrder',
        component: () => import('@views/providers/order'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('@views/alerts'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@views/reports'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth',
    component: () => import('@views/auth'),
    children: [
      {
        alias: '',
        path: 'login',
        name: 'AuthLogin',
        component: () => import('@views/auth/hostedUi.js'),
      },
      {
        path: 'logout',
        name: 'AuthLogout',
        component: () => import('@views/auth/logout.js'),
      },
    ],
  },
  {
    path: '/supply',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('@views/users'),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/404',
    alias: '*',
    component: require('@views/not-found').default,
    props: true,
    meta: {
      auth: false,
    },
  },
];

export default router;
