import apolloClient from '@utils/graphql';
import DRIVER_GET from '@graphql/driver/queries/get.gql';
import DRIVER_LIST from '@graphql/driver/queries/list.gql';
import DRIVER_SIGNUP_LIST from '@graphql/signup/queries/list.gql';
import DRIVER_SIGNUP_INVITE from '@graphql/signup/mutations/invite.gql';
import DRIVER_SIGNUP_PAY from '@graphql/signup/mutations/pay.gql';
import DRIVER_HEADER from '@graphql/driver/queries/header.gql';
import NProgress from 'nprogress/nprogress';
import md5 from 'js-md5';
import gql from 'graphql-tag';
import { DriverEntity } from '@entities/driver';
import { i18next } from '@translate/i18n'

const signupStatus = [
  {
    name: 'docsPending',
    title: i18next.t('drivers.texts.waitingForDocuments'),
    id: 'DriversSignupDocsPending',
    icon: 'fa fa-file',
    total: 0,
    to: '/drivers/signup/docs/pending',
  },
  {
    name: 'docsChecking',
    title: i18next.t('drivers.texts.checkingDocuments'),
    id: 'DriversSignupDocsChecking',
    icon: 'fa fa-check',
    total: 0,
    to: '/drivers/signup/docs/checking',
  },
  {
    name: 'invitePending',
    title: i18next.t('drivers.texts.waitingInvite'),
    id: 'DriversSignupInvite',
    icon: 'fa fa-envelope',
    total: 0,
    to: '/drivers/signup/invite',
  },
  {
    name: 'payments',
    title: i18next.t('drivers.texts.waitingPayment'),
    id: 'DriversSignupPaymentsPending',
    icon: 'fa fa-dollar',
    total: 0,
    to: '/drivers/signup/payments/pending',
  },
  {
    name: 'delivery',
    title: i18next.t('drivers.texts.waitingDelivery'),
    id: 'DriversSignupDeliverySchedules',
    icon: 'fa fa-calendar',
    total: 0,
    to: '/drivers/signup/delivery',
  },
];

const state = {
  driver: {
    id: null,
    email: '',
    photo: '/img/profile-m.png',
    name: 'Carregando...',
    created_at: null,
    status: '',
    booking: null,
    total_fines: 0,
    phone_number: '',
    score: null,
    timezone: null,
  },
  list: { count: 0, items: [] },
  signup_list: { count: 0, items: [] },
  header: null,
  signup_status: signupStatus,
  bookingSelect: {},
};

const getters = {
  driver: state => state.driver,
  header: state => state.header,
  list: state => state.list,
  signupList: state => state.signup_list,
  signupStatus: state => state.signup_status,
  bookingSelect: state => state.bookingSelect
};

const mutations = {
  setBookingSelect(state, param) {
    state.bookingSelect = param
  },

  setDriver(state, driver) {
    state.driver = DriverEntity.of(driver);
  },
  updateDriverEntity(state, { key, value }) {
    if (!value) return;
    if (typeof value === typeof '') value = value.trim();

    state.driver[key] = value;
  },
  setHeader(state, header) {
    state.header = header;
  },
  setDriverList(state, list) {
    list.items = list.items.map((item) => {
      item = DriverEntity.of(item);
      return item;
    });

    if (list) state.list = list;
  },
  setSignupList(state, list) {
    list.items = list.items.map((item) => {
      item = DriverEntity.of(item);
      return item;
    });

    if (list) state.signup_list = list;
  },
  setSignupStatus(state, signup_status) {
    const statusResults = [];
    // eslint-disable-next-line
    for (const item of signupStatus) {
      item.total = signup_status[item.name];
      statusResults.push(item);
    }
    state.signup_status = statusResults;
  },
};

const actions = {
  setBookingSelect({commit}, param) {
    commit('setBookingSelect', param);
  },

  clearDriversList({ commit }) {
    commit('setDriverList', { count: 0, items: [] });
  },
  clearSignupList({ commit }) {
    commit('setSignupList', { count: 0, items: [] });
  },

  clearHeader({ commit }) {
    commit('setHeader', {
      id: null,
      email: '',
      email_verified: false,
      photo: '/img/profile-m.png',
      name: 'Carregando...',
      created_at: null,
      status: '',
      booking: null,
      total_fines: 0,
      phone_number: '',
      phone_number_verified: false,
      timezone: null,
    });
  },

  async header({ commit }, { id }) {
    NProgress.inc();
    try {
      const result = await apolloClient.query({
        query: DRIVER_HEADER,
        variables: {
          id,
        },
      });

      if (result) {
        if (!result.data.driver.photo) {
          let photo = `https://rental.kovi.us/img/profile-${(result.data.driver.gender || 'm').toLowerCase()}.png`;

          if (result.data.driver.email) {
            const md5File = md5(result.data.driver.email);
            photo = `https://www.gravatar.com/avatar/${md5File}?d=${photo}`;
          }

          result.data.driver.photo = photo;
        }
        NProgress.done();
        commit('setHeader', result.data.driver);
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  async byId({ commit }, id) {
    NProgress.inc();
    const driverResult = await apolloClient.query({
      query: DRIVER_GET,
      variables: { id },
    });
    if (driverResult) NProgress.done();

    commit('setDriver', driverResult);
  },

  async signupStatus({ commit }) {
    NProgress.inc();
    apolloClient.cache.reset();
    const status = await apolloClient.query({
      query: gql`
        query DriverCountByStatus {
          driverCountByStatus {
            leadProspect
            docsPending
            docsChecking
            invitePending
            payments
            delivery
          }
        }
      `,
    });
    if (status) NProgress.done();
    commit('setSignupStatus', status.data.driverCountByStatus);
  },

  async list({ commit, state }, {
    status, page = 0, limit = 10, order = 'DESC', filters = {},
  }) {
    NProgress.inc();
    const bDrivers = state.drivers;

    try {
      apolloClient.cache.reset();
      const { data } = await apolloClient.query({
        query: DRIVER_LIST,
        variables: {
          status,
          page,
          limit,
          order,
          filters,
        },
      });
      commit('setDriverList', data.drivers);
    } catch (e) {
      commit('setDriverList', bDrivers);
    }
    NProgress.done();
  },

  async signupList({ commit, state }, {
    step, page = 0, limit = 10, order = 'DESC', filters = {},
  }) {
    NProgress.inc();
    const bDrivers = state.drivers;

    try {
      apolloClient.cache.reset();
      const { data } = await apolloClient.query({
        query: DRIVER_SIGNUP_LIST,
        variables: {
          step,
          page,
          limit,
          order,
          filters,
        },
      });
      commit('setSignupList', data.drivers);
    } catch (e) {
      commit('setSignupList', bDrivers);
    }
    NProgress.done();
  },

  // eslint-disable-next-line
  async sendInvite({ commit }, driverId) {
    NProgress.inc();
    apolloClient.cache.reset();
    const { data } = await apolloClient.mutate({
      mutation: DRIVER_SIGNUP_INVITE,
      variables: {
        id: driverId
      },
    });
    NProgress.done();
    if (data) return true;
    return false;
  },

  // eslint-disable-next-line
  async makeTransactionPaid({ commit }, driverId) {
    NProgress.inc();
    apolloClient.cache.reset();
    const { data } = await apolloClient.mutate({
      mutation: DRIVER_SIGNUP_PAY,
      variables: {
        id: driverId
      },
    });
    NProgress.done();
    if (data) return true;
    return false;
  },

  async byGql({ commit }, { gql, variables = null }) {
    NProgress.inc();
    const { data } = await apolloClient.query({
      query: gql,
      variables,
    });
    NProgress.done();
    if (data.driver) commit('setDriver', data.driver);
    else if (data.drivers) commit('setDriverList', data.drivers);
  },
};

export {
  state, getters, actions, mutations,
};
