export const strict = false;

import Vue from 'vue'
import Vuex from 'vuex'
import PersistedState from 'vuex-persistedstate'
import { KOVI_ADMIN } from '@utils/helper-local-storage'
import modules from '@store/modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    PersistedState({
      key: KOVI_ADMIN,
      paths: ['user']
    }),
  ],
  strict: process.env.NODE_ENV !== 'production',
})

// Automatically run the `init` action for every module,
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

export default store
