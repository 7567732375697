import { getGroupsFromSession } from '@src/lib/cognito';
import TransactionRules from './transactions';
import ScheduleRules from './scheduleRule';

const rules = [
  TransactionRules,
  ScheduleRules
];

class RulesLoader {
  constructor() {
    this.load();
  }

  get rules() {
    return {
      transaction: TransactionRules,
      schedule: ScheduleRules,
    }
  }

  async load() {
    const groups = await getGroupsFromSession();

    rules.forEach((rule) => rule.init(groups));
  }
}

export default new RulesLoader();
