import {
  list,
  scheduleIt as scheduleItService,
  reScheduleIt as reScheduleItService,
  finishIt as finishItService,
  didNotAttend as didNotAttendService,
  changeScheduleStatus as changeScheduleStatusService,
  cycleBack as cycleBackService,
  createScheduleForCar as createScheduleForCarService,
  cancelIt as cancelItService
} from '@graphql/schedules/services';

import { sortListByStatus } from '../../utils/schedule';

const state = {
  list: [],
  listForCar: [],
  deliveryScheduleId: null,
  deliveryScheduleData: {},
  deliverySchedule: {},
}

const getters = {
  list: (state) => state.list,
  listForCar: (state) => state.listForCar,
  deliveryScheduleId: (state) => state.deliveryScheduleId,
  deliveryScheduleData: (state) => state.deliveryScheduleData,
  deliverySchedule: (state) => state.deliverySchedule,
}

const mutations = {
  SET_LIST(state, listSchedule) {
    state.list = listSchedule;
  },

  SET_LIST_FOR_CAR(state, schedule) {
    state.listForCar = schedule;
  },

  SET_DELIVERY_SCHEDULE_ID(state, param) {
    state.deliveryScheduleId = param;
  },

  SET_DELIVERY_SCHEDULE_DATA(state, param) {
    state.deliveryScheduleData = param;
  },

  SET_DELIVERY_SCHEDULE(state, param) {
    state.deliverySchedule = param;
  },

}

const actions = {
  async _listQuery(_, {
    variables = {}
  } = {}) {
    const { data } = await list(variables);

    const {
      Maintenance: {
        schedules: { items },
      },
    } = data;

    return items;
  },

  async getAll({ commit, dispatch }, { variables = {} } = {}) {
    commit('SET_LIST', await dispatch('_listQuery', variables));
  },

  async getListForCar({ commit, dispatch }, carId) {
    const items = await dispatch('_listQuery', {
      variables: {
        filter: {
          car: carId,
        },
        order: [
          {
          field: 'scheduled_at',
          direction: 'DESCENDING',
        }
        ]
      }
    });

    const ordenedList = sortListByStatus(items);

    commit('SET_LIST_FOR_CAR', ordenedList);
  },

  async createScheduleForCar(_, { variables = {} }) {
    const { data } = await createScheduleForCarService(variables);
    const { Maintenance: { createScheduleForCar } } = data;

    return createScheduleForCar;
  },

  async scheduleIt(_, { variables } = {}) {
    const { data } = await scheduleItService(variables);

    const { Maintenance: { scheduleIt } } = data;
    return scheduleIt;
  },

  async reScheduleIt(_, { scheduleId, date, garage } = {}) {
    const { data } = await reScheduleItService({
      scheduleId,
      date,
      garage,
    });

    const { Maintenance: { scheduleIt } } = data;
    return scheduleIt;
  },

  async finishIt(_, { variables } = {}) {
    const { data } = await finishItService(variables);

    const { Maintenance: { finishIt } } = data;
    return finishIt;
  },

  async cancelIt(_, { variables } = {}) {
    const { data } = await cancelItService(variables);

    const { Maintenance: { cancelIt } } = data;
    return cancelIt;
  },

  async didNotAttend(_, { variables } = {}) {
    const { data } = await didNotAttendService(variables);

    const { Maintenance: { didNotAttend } } = data;
    return didNotAttend;
  },

  async changeScheduleStatus(_, { variables } = {}) {
    const { data } = await changeScheduleStatusService(variables);

    const { Maintenance: { changeScheduleStatus } } = data;
    return changeScheduleStatus;
  },

  async cycleBack(_, { variables } = {}) {
    const { data } = await cycleBackService(variables);

    const { Maintenance: { cycleBackToSchedule } } = data;
    return cycleBackToSchedule;
  },

  setDeliveryScheduleId({ commit, dispatch }, param) {
    commit('SET_DELIVERY_SCHEDULE_ID', param);
  },

  setDeliveryScheduleData({ commit }, param) {
    commit('SET_DELIVERY_SCHEDULE_DATA', param);
  },

  setDeliverySchedule({ commit }, param) {
    commit('SET_DELIVERY_SCHEDULE', param);
  },
}


export {
  state,
  getters,
  mutations,
  actions,
}
