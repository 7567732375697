export const state = {
  token: null,
}

export const getters = {
  token: state => {
    return state.token
  },
}

export const mutations = {
  setToken(state, _token) {
    state.token = _token
  },
}
