import { list, create, update, findByDistance as findByDistanceService } from '@graphql/garages/services';
import { isValidGarage } from '@entities/garage'

const state = {
  list: [],
  modalData: {},
}

const getters = {
  list: (currentState) => currentState.list,
  modalData: currentState => currentState.modalData,
}

const mutations = {
  SET_LIST(currentState, listGarage) {
    currentState.list = listGarage;
  },

  setModalData(state, value) {
    state.modalData = value;
  },

  resetModalData(state) {
    state.modalData = {};
  }
}

const actions = {
  async getAll({ commit }, { variables = {} }) {
    const { data } = await list(variables);

    const {
      Maintenance: {
        garages: { items },
      },
    } = data;

    commit('SET_LIST', items);
  },

  async createGarage(_, garageData) {

    if (!isValidGarage(garageData)) throw ("Verifique os dados informados");

    return create(garageData);
  },

  async updateGarage(_, garageData) {

    if (!isValidGarage(garageData)) throw ("Verifique os dados informados");

    return update(garageData);
  },

  async findByDistance(_, {
    latLong,
    distance,
    filter,
  } = {}) {
    const {
      data: {
        Maintenance: {
          findGaragesByDistance: {
            items
          }
        }
      }
    } = await findByDistanceService({
      latLong,
      distance,
      filter,
    });

    return items?.filter(({status}) => status === "ACTIVE") || [];
  },

  async listAll(_, { variables = {} }) {
    const { data } = await list(variables);

    const {
      Maintenance: {
        garages: { items },
      },
    } = data;

    return items;
  },
}

export {
  state,
  getters,
  mutations,
  actions,
}
