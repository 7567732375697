import Vue from 'vue';
import { enumFleetFlags, enumOBDProvider } from '@utils/car';

const permissionsOperations = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  LIST: 'LIST',
  PUBLIC: 'PUBLIC',
}

const hasPermission = (tag, operation) => {
  return tag.permissions.some(({ operations }) => operations.includes(operation));
}

const disabled = (tag, carTags) => {
  if (tag.isPublic) return false;
  const isChecked = carTags.find(ct => ct.id === tag.id);
  if (isChecked) {
    return !hasPermission(tag, permissionsOperations.REMOVE);
  }
  return !hasPermission(tag, permissionsOperations.ADD);
};

const state = {
  tags: [],
  carTags: [],
  is_load_fleet_tags: true
}

const getters = {
  tagsOptions: state => state.tags.map(tag => {
        const data = enumFleetFlags.concat(enumOBDProvider).find(enumTag => enumTag.value === tag.name);
        return {
          disabled: disabled(tag, state.carTags),
          text: data?.text || tag.name,
          value: tag.name,
          category: tag?.category,
        }
      }
  ),
  tagsSelected: state => state.carTags.map((carTag) => carTag.name),
  is_load_fleet_tags: state => state.is_load_fleet_tags
}

const mutations = {
  setTags(state, obj) {
    state.tags = obj;
  },
  setCarTags(state, obj) {
    state.carTags = obj;
  },
  setLoad(state, obj){
    state.is_load_fleet_tags = obj;
  }
}

const actions = {
  getTags({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setTags', []);
      commit('setLoad', true);
      Vue.prototype.$fleetProvider.get('/fleet-tags/tags/all')
        .then((res) => {
          commit('setTags', res.data);
          commit('setLoad', false);
          return resolve(res.data);
        })
        .catch((error)=>{
          return reject(error);
        })
    })
  },
  getCarTags({ commit }, fleet_id) {
    return new Promise((resolve, reject) => {
      commit('setLoad', true);
      Vue.prototype.$fleetProvider.get(`/fleet-tags/fleet-tags/list-tags/${fleet_id}`)
        .then((res) => {
          commit('setCarTags', res.data);
          commit('setLoad', false);
          resolve(res.data);
        })
        .catch((error) => {
          commit('setCarTags', []);
          reject(error);
        })
    })
  },
  updateCarTags(_, { fleet_id, tags }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$fleetProvider.put(`/fleet-tags/fleet-tags/batch/${fleet_id}`, { tags })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}

export { state, getters, mutations, actions }
