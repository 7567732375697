const RULE_TRANSACTIONS_CANCEL = 'RULE:TRANSACTIONS:CANCEL';
const RULE_TRANSACTIONS_CREATE = 'RULE:TRANSACTIONS:CREATE';

class TransactionRule {
  init(groups) {
    this.groups = groups;
    this.canCancel();
    this.canCreate();
  }
  async canCancel() {
    this.cancel = this.groups.includes(RULE_TRANSACTIONS_CANCEL);
  }
  async canCreate() {
    this.create = this.groups.includes(RULE_TRANSACTIONS_CREATE);
  }
}

export default new TransactionRule();
