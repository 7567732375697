const state = {
  contract: {
    id: '',
    comnpany: ''
  },
  order: {
    id: '',
    number: ''
  }
}

const getters = {
  getContract: state => state.contract,
  getOrder: state => state.order
}

const mutations = {
  mutContract (state, contract) {
    state.contract = contract
  },
  mutOrder (state, order) {
    state.order = order
  }
}

const actions = {
  setContract({commit},contract) {
    commit('mutContract', contract )
  },
  setOrder({commit},order) {
    commit('mutOrder', order )
  }
}

export { state, getters, actions, mutations }
