const RULE_SCHEDULE_VIEW = 'RULE:SCHEDULE:VIEW';

class scheduleRule {
  init(groups) {
    try {
      this.groups = groups;
      this.scheduleView();
    } catch (err) {
      console.error(err);
    }
  }
  async scheduleView() {
    this.scheduleView = this.groups.includes(RULE_SCHEDULE_VIEW);
  }
}

export default new scheduleRule();
